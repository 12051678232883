const label = 'Demo HiPay';
const defaultPaymentConfig = {
  carousel: {},
  request: {
    amount: '100',
    currency: 'EUR',
    locale: 'fr_FR',
    currencyCode: 'EUR',
    countryCode: 'FR',
    supportedNetworks: [
      'cartesBancaires',
      'visa',
      'masterCard',
      'amex',
      'maestro'
    ],
    total: {
      label,
      amount: '100'
    }
  },
  card: {
    brand: ['visa', 'cb', 'mastercard', 'maestro', 'bcmc', 'american-express'],
    fields: {
      cardHolder: {
        uppercase: true,
        helpButton: false
      },
      cardNumber: {
        helpButton: false,
        hideCardTypeLogo: false
      },
      expiryDate: {
        helpButton: false
      },
      cvc: {
        helpButton: true
      }
    },
    one_click: {
      enabled: true,
      cards_display_count: 3,
      cards: JSON.parse(localStorage.getItem('saved_cards')) || []
    }
  },
  paypal: {
    paypalButtonStyle: {
      color: 'gold',
      shape: 'pill',
      label: 'pay',
      height: 40
    },
    canPayLater: true
  },
  sdd: {
    client_type: 'all',
    fields: {
      client_type: {
        helpButton: false
      },
      gender: {
        helpButton: false
      },
      firstname: {
        helpButton: false,
        uppercase: false
      },
      lastname: {
        helpButton: false,
        uppercase: false
      },
      company: {
        helpButton: false,
        uppercase: false
      },
      company_type: {
        helpButton: false
      },
      bank_name: {
        helpButton: false,
        uppercase: false
      },
      iban: {
        helpButton: false
      }
    }
  },
  paymentRequestButton: {
    // TO DO: remove it after fix HF
    // Need fix from HF to apply global request to paymentRequestButton when HP mode
    request: {
      currencyCode: 'EUR',
      countryCode: 'FR',
      supportedNetworks: [
        'cartesBancaires',
        'visa',
        'masterCard',
        'amex',
        'maestro'
      ],
      total: {
        label,
        amount: '100'
      }
    },
    applePayStyle: {
      type: 'plain',
      color: 'black'
    },
    selector: 'payment-request-button-container',
    displayName: label,
    onlyActiveCard: false
  },
  display_cancel_button: 0
};

export default defaultPaymentConfig;
