const defaultStylesConfig = {
  base: {
    color: '#000000',
    caretColor: '#000000',
    placeholderColor: '#BFBFBF',
    iconColor: '#737373',
    fontFamily: 'Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: 'none'
  },
  components: {
    switch: {
      mainColor: '#02A17B'
    },
    checkbox: {
      mainColor: '#02A17B'
    }
  },
  valid: {
    color: '#000000',
    caretColor: '#000000'
  },
  invalid: {
    color: '#CB2B0B',
    caretColor: '#000000'
  },
  disable: {
    color: 'rgba(0, 0, 0, 0.38)',
    placeholderColor: '#BFBFBF'
  }
};

export default defaultStylesConfig;
