import axios from 'axios';

const availablePaymentProducts = [
  '3xcb',
  '3xcb-no-fees',
  '4xcb',
  '4xcb-no-fees',
  'alma-3x',
  'alma-4x',
  'bancontact',
  'bcmc-mobile',
  'bnpp-3xcb',
  'bnpp-4xcb',
  'card',
  'carte-cadeau',
  'credit-long',
  'giropay',
  'ideal',
  'illicado',
  'klarna',
  'mbway',
  'multibanco',
  'mybank',
  'paypal',
  'payshop',
  'paysafecard',
  'postfinance-card',
  'postfinance-efinance',
  'przelewy24',
  'sdd',
  'sisal',
  'sofort-uberweisung'
];

export const availablePaypalV2Product = (client) => {
  return new Promise((resolve) => {
    axios
      .get(client.url, {
        auth: {
          username: client.username,
          password: client.password
        },
        params: {
          payment_product: 'paypal',
          eci: 7,
          operation: 4,
          with_options: true
        }
      })
      .then((response) => {
        if (response.data.length && response.data[0].code === 'paypal') {
          return resolve(
            response.data[0].options?.payer_id &&
              response.data[0].options.provider_architecture_version === 'v1'
          );
        }
        return resolve();
      });
  });
};

export default availablePaymentProducts;
