import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { HiAlert } from '@hipay/design-system/components';

import { FormGroup } from '@mui/material';
import {
  getPaymentDraftConfig,
  isMethodEnabled
} from '../../selectors/configSelectors';
import {
  updatePaymentProductConfig,
  updateDisplayCancelButton,
  updateCardNetworksAuthorized
} from '../../actions/sdkConfigActions';

import { HiSwitch } from '@hipay/design-system/components';
import NetworkFilter from '../NetworkFilter/NetworkFilter';

function ConfigHPagePayment(props) {
  let handleSwitchChange = (method, field, name) => (event) => {
    props.updatePaymentProductConfig(method, field, name, event.target.checked);
  };

  let handleSwitchCancelButtonChange = (name) => (event) => {
    props.updateDisplayCancelButton(name, event.target.checked ? 1 : 0);
  };

  return (
    <>
      {props.paypalEnabled && (
        <div className="ConfigPayPalV2">
          <h2>
            <FormattedMessage id={'paypal-v2-default-config'} />
          </h2>

          {props.isPaypalV2Available === false && (
            <HiAlert severity="warning">
              PayPal v2 is not enabled with this account. The following options
              will not have any effect.
            </HiAlert>
          )}

          <FormGroup name="groupName" className="max-content-width">
            <HiSwitch
              id={'paypal.show_bnpl'}
              checked={props.config.paypal.canPayLater}
              onChange={handleSwitchChange('paypal', 'option', 'canPayLater')}
              label={<FormattedMessage id={'show-bnpl'} />}
            />
          </FormGroup>
        </div>
      )}
      {props.cardEnabled && (
        <>
          <h2>
            <FormattedMessage id={'card-default-config'} />
          </h2>
          <div className="block-container">
            <NetworkFilter />
          </div>
        </>
      )}
      <h2>
        <FormattedMessage id={'hpage-config'} />
      </h2>
      <FormGroup name="groupName" className="max-content-width">
        <HiSwitch
          id={'global.display_cancel_button'}
          checked={props.config.display_cancel_button === 1}
          onChange={handleSwitchCancelButtonChange('display_cancel_button')}
          label={<FormattedMessage id={'display_cancel_button'} />}
        />
      </FormGroup>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    config: getPaymentDraftConfig(state),
    cardEnabled: isMethodEnabled(state, 'card'),
    paypalEnabled: isMethodEnabled(state, 'paypal')
  };
};

export default connect(mapStateToProps, {
  updatePaymentProductConfig,
  updateDisplayCancelButton,
  updateCardNetworksAuthorized
})(injectIntl(ConfigHPagePayment));
