import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup
} from '@mui/material';

import {
  removeOneClickSavedCardLimit,
  updateIsPayPalV2Available,
  updateOneClickSavedCards,
  updatePaymentProductConfig,
  updateCardNetworksAuthorized
} from '../../actions/sdkConfigActions';
import {
  getClientConfig,
  getPaymentDraftConfig,
  isMethodEnabled,
  isPaypalV2Available
} from '../../selectors/configSelectors';

import {
  HiAlert,
  HiButton,
  HiFormControl,
  HiInput,
  HiSwitch
} from '@hipay/design-system/components';
import { useEffect, useState } from 'react';
import { availablePaypalV2Product } from '../../reducers/available-payment-products';
import NetworkFilter from '../NetworkFilter/NetworkFilter';

function ConfigHF(props) {
  const [savedCardsValue, setSavedCardsValue] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  let handleTextChange = (field, name) => (value) => {
    props.updatePaymentProductConfig('card', field, name, value);
  };

  let handleNumberChange = (field, name) => (value) => {
    if (value === '') {
      setSavedCardsValue(value);
      props.removeOneClickSavedCardLimit();
    } else if (!isNaN(value) && Number(value) >= 1) {
      setSavedCardsValue(value);
      props.updatePaymentProductConfig('card', field, name, Number(value));
    }
  };

  let handleSwitchChange = (method, field, name) => (event) => {
    props.updatePaymentProductConfig(method, field, name, event.target.checked);
  };

  let clearSavedCards = () => {
    localStorage.removeItem('saved_cards');
    props.updateOneClickSavedCards([]);
    handleDialogClose();
  };

  let handleDialogOpen = () => {
    setOpenDialog(true);
  };

  let handleDialogClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (props.isPaypalV2Available === null) {
      availablePaypalV2Product(props.client).then((val) => {
        props.updateIsPayPalV2Available(val);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSavedCardsValue(props.config.card.one_click.cards_display_count);
  }, [props.config.card.one_click.cards_display_count]);

  return (
    <>
      {props.paypalEnabled && (
        <div className="ConfigPayPalV2">
          <h2>
            <FormattedMessage id={'paypal-v2-default-config'} />
          </h2>

          {props.isPaypalV2Available === false && (
            <HiAlert severity="warning">
              PayPal v2 is not enabled with this account. The following options
              will not have any effect.
            </HiAlert>
          )}

          <FormGroup name="groupName" className="max-content-width">
            <HiSwitch
              id={'paypal.show_bnpl'}
              checked={props.config.paypal.canPayLater}
              onChange={handleSwitchChange('paypal', 'option', 'canPayLater')}
              label={<FormattedMessage id={'show-bnpl'} />}
            />
          </FormGroup>
        </div>
      )}
      {props.cardEnabled && (
        <div className="ConfigHFcard">
          <h2>
            <FormattedMessage id={'card-default-config'} />
          </h2>

          <div className="block-container">
            <div className="block">
              <h3>
                <FormattedMessage id={'one-click'} />
              </h3>
              <FormGroup name="groupName" className="max-content-width">
                <HiSwitch
                  id={'global.one_click'}
                  checked={props.config.card.one_click.enabled}
                  onChange={handleSwitchChange('card', 'one_click', 'enabled')}
                  label={<FormattedMessage id={'enabled'} />}
                />
                <HiFormControl
                  id="cards-display-count-one-click"
                  name="cards-display-count-one-click"
                  label={props.intl.formatMessage({
                    id: 'cards-display-count-one-click'
                  })}
                >
                  <HiInput
                    type="text"
                    value={savedCardsValue || ''}
                    onChange={handleNumberChange(
                      'one_click',
                      'cards_display_count'
                    )}
                  />
                </HiFormControl>
                {localStorage.getItem('saved_cards') && (
                  <HiButton
                    style={{
                      maxWidth: '400px',
                      marginTop: '12px',
                      marginBottom: '16px'
                    }}
                    variant="text"
                    onClick={handleDialogOpen}
                  >
                    <FormattedMessage id={'clear-saved-cards'} />
                  </HiButton>
                )}
                <Dialog
                  open={openDialog}
                  onClose={handleDialogClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    Confirmation
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <FormattedMessage
                        id={'delete-one-click-saved-cards-confirmation-content'}
                      />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <HiButton onClick={clearSavedCards}>
                      <FormattedMessage id={'yes'} />
                    </HiButton>
                    <HiButton onClick={handleDialogClose} autoFocus>
                      <FormattedMessage id={'no'} />
                    </HiButton>
                  </DialogActions>
                </Dialog>
              </FormGroup>
            </div>
            <NetworkFilter />
          </div>

          <div className="block-container">
            <div className="block">
              <h3>
                <FormattedMessage id={'cardHolder'} />
              </h3>

              <FormGroup name="groupName" className="max-content-width">
                <HiSwitch
                  id={'cardHolder.helpButton'}
                  checked={props.config.card.fields.cardHolder.helpButton}
                  onChange={handleSwitchChange(
                    'card',
                    'cardHolder',
                    'helpButton'
                  )}
                  label={<FormattedMessage id={'helpButton'} />}
                />
                <HiSwitch
                  id={'cardHolder.uppercase'}
                  checked={props.config.card.fields.cardHolder.uppercase}
                  onChange={handleSwitchChange(
                    'card',
                    'cardHolder',
                    'uppercase'
                  )}
                  label={<FormattedMessage id={'force-uppercase'} />}
                />
              </FormGroup>

              <HiFormControl
                className="textField"
                id="defaultFirstname"
                name="defaultFirstname"
                label={props.intl.formatMessage({ id: 'defaultFirstname' })}
              >
                <HiInput
                  value={
                    props.config.card.fields.cardHolder.defaultFirstname ?? ''
                  }
                  onChange={handleTextChange('cardHolder', 'defaultFirstname')}
                  placeholder="John"
                />
              </HiFormControl>

              <HiFormControl
                className="textField"
                id="cardHolder.defaultLastname"
                name="cardHolder.defaultLastname"
                label={props.intl.formatMessage({ id: 'defaultLastname' })}
              >
                <HiInput
                  value={
                    props.config.card.fields.cardHolder.defaultLastname ?? ''
                  }
                  onChange={handleTextChange('cardHolder', 'defaultLastname')}
                  placeholder="Doe"
                />
              </HiFormControl>

              <HiFormControl
                className="textField"
                id="cardHolder.placeholder"
                name="cardHolder.placeholder"
                label={props.intl.formatMessage({ id: 'placeholder' })}
              >
                <HiInput
                  value={props.config.card.fields.cardHolder.placeholder ?? ''}
                  onChange={handleTextChange('cardHolder', 'placeholder')}
                  placeholder="John Doe"
                />
              </HiFormControl>
            </div>

            <div className="block">
              <h3>
                <FormattedMessage id={'cardNumber'} />
              </h3>
              <FormGroup name="groupName" className="max-content-width">
                <HiSwitch
                  id={'cardNumber.helpButton'}
                  checked={props.config.card.fields.cardNumber.helpButton}
                  onChange={handleSwitchChange(
                    'card',
                    'cardNumber',
                    'helpButton'
                  )}
                  label={<FormattedMessage id={'helpButton'} />}
                />
                <HiSwitch
                  id={'cardNumber.hideCardTypeLogo'}
                  checked={props.config.card.fields.cardNumber.hideCardTypeLogo}
                  onChange={handleSwitchChange(
                    'card',
                    'cardNumber',
                    'hideCardTypeLogo'
                  )}
                  label={<FormattedMessage id={'hideCardTypeLogo'} />}
                />
              </FormGroup>
              <HiFormControl
                className="textField"
                id="cardNumber.placeholder"
                name="cardNumber.placeholder"
                label={props.intl.formatMessage({ id: 'placeholder' })}
              >
                <HiInput
                  value={props.config.card.fields.cardNumber.placeholder ?? ''}
                  onChange={handleTextChange('cardNumber', 'placeholder')}
                  placeholder="Placeholder"
                />
              </HiFormControl>
            </div>

            <div className="block">
              <h3>
                <FormattedMessage id={'expiryDate'} />
              </h3>

              <HiFormControl
                className="textField"
                id="expiryDate.placeholder"
                name="expiryDate.placeholder"
                label={props.intl.formatMessage({ id: 'placeholder' })}
              >
                <HiInput
                  value={props.config.card.fields.expiryDate.placeholder ?? ''}
                  onChange={handleTextChange('expiryDate', 'placeholder')}
                  placeholder="Placeholder"
                />
              </HiFormControl>

              <FormGroup name="groupName" className="max-content-width">
                <HiSwitch
                  id={'expiryDate.helpButton'}
                  checked={props.config.card.fields.expiryDate.helpButton}
                  onChange={handleSwitchChange(
                    'card',
                    'expiryDate',
                    'helpButton'
                  )}
                  label={<FormattedMessage id={'helpButton'} />}
                />
              </FormGroup>
            </div>
            <div className="block">
              <h3>
                <FormattedMessage id={'cvc'} />
              </h3>

              <HiFormControl
                className="textField"
                id="cvc.placeholder"
                name="cvc.placeholder"
                label={props.intl.formatMessage({ id: 'placeholder' })}
              >
                <HiInput
                  value={props.config.card.fields.cvc.placeholder ?? ''}
                  onChange={handleTextChange('cvc', 'placeholder')}
                  placeholder="Placeholder"
                />
              </HiFormControl>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    client: getClientConfig(state),
    config: getPaymentDraftConfig(state),
    cardEnabled: isMethodEnabled(state, 'card'),
    paypalEnabled: isMethodEnabled(state, 'paypal'),
    isPaypalV2Available: isPaypalV2Available(state)
  };
};

export default connect(mapStateToProps, {
  updatePaymentProductConfig,
  updateCardNetworksAuthorized,
  updateIsPayPalV2Available,
  updateOneClickSavedCards,
  removeOneClickSavedCardLimit
})(injectIntl(ConfigHF));
