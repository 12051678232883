import './NetworkFilter.scss';

import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  getPaymentDraftConfig,
  isMethodEnabled
} from '../../selectors/configSelectors';
import { updateCardNetworksAuthorized } from '../../actions/sdkConfigActions';
import { FormGroup, SvgIcon } from '@mui/material';
import { HiCheckbox } from '@hipay/design-system/components';

function NetworkFilter(props) {
  const [cardNetworks, setCardNetworks] = useState([
    {
      label: 'Visa',
      code: 'visa',
      applePayCode: 'visa',
      selected: true
    },
    {
      label: 'Carte Bancaire',
      code: 'cb',
      applePayCode: 'cartesBancaires',
      selected: true
    },
    {
      label: 'Maestro',
      code: 'maestro',
      applePayCode: 'maestro',
      selected: true
    },
    {
      label: 'Mastercard',
      code: 'mastercard',
      applePayCode: 'masterCard',
      selected: true
    },
    {
      label: 'Bancontact',
      code: 'bcmc',
      applePayCode: 'bancontact',
      selected: true
    },
    {
      label: 'American Express',
      code: 'american-express',
      applePayCode: 'amex',
      selected: true
    }
  ]);

  let handleCardNetworkChanged = (cardNetworkChanged) => {
    let cardNetworksTmp = [...cardNetworks];
    let netWorksSelected = [];
    let applePayNetWorksSelected = [];
    cardNetworksTmp.forEach((cardNetwork) => {
      if (cardNetwork.code === cardNetworkChanged.code) {
        cardNetwork.selected = !cardNetwork.selected;
      }

      if (cardNetwork.selected) {
        netWorksSelected.push(cardNetwork.code);
        applePayNetWorksSelected.push(cardNetwork.applePayCode);
      }
    });
    setCardNetworks(cardNetworksTmp);
    props.updateCardNetworksAuthorized(
      netWorksSelected,
      applePayNetWorksSelected
    );
  };

  useEffect(() => {
    let selectedCardNetworks = props.config.card.brand;
    let cardNetworksTmp = [...cardNetworks];
    cardNetworksTmp.forEach((cardNetwork) => {
      if (selectedCardNetworks.includes(cardNetwork.code)) {
        cardNetwork.selected = true;
      } else {
        cardNetwork.selected = false;
      }
    });
    setCardNetworks(cardNetworksTmp);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.config.card.brand]);

  return (
    <div className="block">
      <h3>
        <FormattedMessage id={'credit-card-networks'} />
      </h3>
      <FormattedMessage id={'credit-card-networks-description'} />
      <FormGroup name="groupName" className="card-networks max-content-width">
        {cardNetworks.map((cardNetwork) => {
          return (
            <HiCheckbox
              id={`${cardNetwork.code}_checkbox_id`}
              key={`${cardNetwork.code}_checkbox`}
              checked={cardNetwork.selected}
              label={
                <div className="card-brand-checkbox-container">
                  <SvgIcon component="div">
                    <img
                      src={`https://libs.hipay.com/hostedfields/img/card-types/${cardNetwork.code}.svg`}
                      alt="danger"
                      height="24px"
                      width="24px"
                    />
                  </SvgIcon>
                  {cardNetwork.label}
                </div>
              }
              onChange={() => handleCardNetworkChanged(cardNetwork)}
            />
          );
        })}
      </FormGroup>
      <span className="footer-info">
        <i>
          <FormattedMessage id={'credit-card-networks-info'} />
        </i>
      </span>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    config: getPaymentDraftConfig(state),
    cardEnabled: isMethodEnabled(state, 'card')
  };
};

export default connect(mapStateToProps, {
  updateCardNetworksAuthorized
})(NetworkFilter);
