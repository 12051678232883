import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FormGroup } from '@mui/material';
import { HiSwitch } from '@hipay/design-system/components';
import { updateHPageIFrameConfig } from '../../../actions/sdkConfigActions';
import { isHPageIFrame } from '../../../selectors/configSelectors';

const ConfigEnvHPage = (props) => {
  const handleSwitchChange = (event) => {
    props.updateHPageIFrameConfig('iframe', event.target.checked);
  };

  return (
    <div>
      <h2>
        <FormattedMessage id={'environment-hpage'} />
      </h2>
      <div className="help">
        <FormattedMessage id={'environment-hpage-help-1'} />
        <br />
        <FormattedMessage id={'environment-hpage-help-2'} />
      </div>
      <FormGroup name="groupName" className="max-content-width">
        <HiSwitch
          id={'global.iframe'}
          checked={props.iframe}
          onChange={handleSwitchChange}
          label={<FormattedMessage id={'iframe'} />}
        />
      </FormGroup>
    </div>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    iframe: isHPageIFrame(state)
  };
};

export default connect(mapStateToProps, { updateHPageIFrameConfig })(
  ConfigEnvHPage
);
